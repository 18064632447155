import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomPollOnboardingService } from '../../services/custom-poll-onboarding.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-onboarding-categories',
  templateUrl: './onboarding-categories.component.html',
  styleUrls: ['./onboarding-categories.component.scss']
})
export class OnboardingCategoriesComponent implements OnInit {
  
  @Input() set setResumedDraft(value: any) {
    this.resumedDraft = value;
  }
  
  @Output() checkinsCategoriesEvent = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();
  
  checkinsForm: FormGroup;
  
  checkinsCategories: any[] = [];
  checkinCategoryStatements: any[] = [];
  selectedCategory: any[] = [];
  selectedCategoryToShowStatements: any[] = [];
  statementsExtraQuestions: any[] = [];
  processExtraQuestions: any[] = [];
  
  resumedDraft: any;

  defaultCategoryStart: boolean = true;

  constructor(private onboardingService: CustomPollOnboardingService, private fb: FormBuilder, private translate: TranslateService) {
    this.checkinsForm = this.fb.group({
      checkinCategory: this.fb.array([])
    });
    
   }

  ngOnInit(): void {
    this.getCheckins();
  }

  getCheckins() {
    this.onboardingService.getChekinsCategories().subscribe((res: any) => {
      let selectedCheckinsCategories: any[] = [];
      this.checkinsCategories = res._embedded["timed-collections"];
      if (this.resumedDraft) {
        this.onboardingService.getChekinsSelectedCategories(this.resumedDraft.uuid).subscribe((res: any) => {
          selectedCheckinsCategories = res._embedded["timed-collections"];
          this.checkinsCategories.forEach((check: any, index:number) => {
            check.selected = false;
            if (check.slug === 'week-2-check-in' || check.slug === 'month-1-check-in') {
              check.selected = true;
              this.onChange(check.uuid, check.title, true, true);
            } else if (selectedCheckinsCategories.length && (index < selectedCheckinsCategories.length)) {
              if ((check.slug !== 'week-2-check-in' || check.slug !== 'month-1-check-in') && check.uuid === selectedCheckinsCategories[index].timedQuestionCollection.uuid) {
                check.selected = true;
                this.onChange(check.uuid, check.title, false, true);
              }
            }
          });
          
        });
      } else {
        this.checkinsCategories.forEach((check: any, index:number) => {
          check.selected = false;
          if (check.slug === 'week-2-check-in' || check.slug === 'month-1-check-in') {
            check.selected = true;
            this.onChange(check.uuid, check.title, true, true);
          }
        });
      }
    });
  }

  getCheckinStatements(collectionUuid: string, collectionIndex: number) {
    this.statementsExtraQuestions = [];
    this.defaultCategoryStart = false;
    this.selectedCategoryToShowStatements = new Array(this.checkinsCategories.length).fill(false);
    this.selectedCategoryToShowStatements[collectionIndex] = true;
    this.onboardingService.getChekinsCategoriesStatements(collectionUuid).subscribe((res:any) =>{
      this.checkinCategoryStatements = res._embedded["timed-collection-questions"];
      this.processStatementsExtraQuestions(this.checkinCategoryStatements);
    });
  }

  processStatementsExtraQuestions(statements: any) {
    statements.forEach((state: any, index: number) => {
      this.processExtraQuestion(state);
      const extraQuestions = this.removeDuplicates(this.processExtraQuestions, 'title');
      this.statementsExtraQuestions.push(extraQuestions);
      this.processExtraQuestions = [];
    });
  }

  removeDuplicates(myArray: any, prop: any) {
    return myArray.filter((obj: { [x: string]: any; }, pos: any, arr: any[]) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  processExtraQuestion(extraQuestion: any): any {
    if (extraQuestion.extraQuestion) {
      extraQuestion.extraQuestion.extra = true;
      this.processExtraQuestions.push(extraQuestion.extraQuestion);
      return this.processExtraQuestion(extraQuestion.extraQuestion);
    } else if (extraQuestion.responses.length) {
      extraQuestion.responses.forEach((response: any) => {
        if (response.extraQuestion) {
          response.extraQuestion.extra = true;
          this.processExtraQuestions.push(response.extraQuestion);
          return this.processExtraQuestion(response.extraQuestion);
        }
      });
    }
  }

  onChange(categoryUuid: string, categoryTitle: string, isMandatory: boolean, isChecked: any) {
    const categoryFromArray = <FormArray>this.checkinsForm.controls['checkinCategory'];

    if (isChecked?.target?.checked || isChecked === true) {
      categoryFromArray.push(new FormControl({categoryUuid: categoryUuid, title: categoryTitle, isMandatory: isMandatory}));
    } else {
      let index = categoryFromArray.controls.findIndex(x => x.value.categoryUuid == categoryUuid)
      categoryFromArray.removeAt(index);
    }
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

  nextStep() {
    this.checkinsCategoriesEvent.emit({checkinCategories: this.checkinsForm.value.checkinCategory});
  }

  onOpenAccordion(event: any, index: number) {
    
    
  }

}
