<div class="main-container">
    <div class="loop-container loop-results">
        <div class="wss-left">
            <h2 class="wss-left-title">Overall Score</h2>
            <div *ngIf="reportingDetailsValues.type === 'onboarding'" class="wss-left-increase-or-decrease-con">
                <div class="increase-or-decrease-con">
                    <span style="font-size: 2.4rem;font-weight: 700;">-%</span>
                    <div *ngIf="false" class="arrow-up-down-con">
                        <i class="bi bi-dash-lg"></i>
                    </div>
                </div>
                <span>{{'custom_poll_main_reporting_details_change_over' | translate}}</span>
            </div>
        </div>
        <div class="wss-right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="22 12 95 90" version="1.1" width="239px" height="212px">
              <path class="grey" d="M40,90
                  A40,40 0 1,1 100,90"
                    style="fill:none;"/>
              <path [attr.stroke]="knobColor" [attr.stroke-dasharray]="setKnobProgressBar()" id="svgProgress"
                    class="progress" d="M40,90
                  A40,40 0 1,1 100,90"
                    style="fill:none;"/>
              <text class="svgTextValue"
              [attr.x]="reportingDetailsValues.reportingDetails.score >= 10 ? 51 : 61" [attr.y]="73">{{reportingDetailsValues.reportingDetails.score}}</text>
              <text class="svgTextPercentage"
              [attr.x]="reportingDetailsValues.reportingDetails.score >= 10 ? 83 : 78" [attr.y]="72">%</text>
            </svg>
        </div>
    </div>
    <div class="loop-container loop-response-rate">
        <h2 style="font-size: 1.6rem;">{{'custom_poll_main_reporting_details_resrate' | translate}}</h2>
        <div class="reporting-response-rate-container" [ngClass]="responseRateBackgroundColor ? responseRateBackgroundColor:''">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <g filter="url(#filter0_d_430_11651)">
                <circle cx="21.7715" cy="21.4248" r="20.4248" [attr.fill]="responseRateColor"/>
                </g>
                <path d="M17.2798 31.8525L13.5502 27.6322C13.3817 27.4453 13.3817 27.16 13.5502 26.9657L17.2798 22.7503C17.5897 22.4011 18.1603 22.6224 18.1603 23.0823V25.8073H25.1068C26.0573 25.8073 26.8345 25.0302 26.8345 24.0784V23.1389C26.8345 22.6987 27.0288 22.2855 27.3657 22.0002L28.7835 20.8148C29.1906 20.4656 29.8251 20.7631 29.8251 21.3017V24.1423C29.8251 26.7124 27.7408 28.7979 25.1707 28.7979H18.1603V31.5229C18.1603 31.8254 17.9119 32.0222 17.6536 32.0222C17.5208 32.0222 17.3856 31.9705 17.2798 31.8525ZM13.7186 21.5526V18.712C13.7186 16.142 15.803 14.0638 18.373 14.0638H25.3847V11.3388C25.3847 10.8789 25.954 10.6576 26.2651 11.0019L29.9936 15.2222C30.162 15.4165 30.162 15.7017 29.9936 15.8887L26.2651 20.104C25.954 20.4533 25.3847 20.2393 25.3847 19.7745V17.0544H18.4382C17.4803 17.0544 16.7092 17.8242 16.7092 18.776V19.7154C16.7092 20.1557 16.515 20.5689 16.1793 20.8541L14.7614 22.0396C14.6372 22.1429 14.4946 22.1896 14.3556 22.1896C14.0285 22.1896 13.7186 21.9313 13.7186 21.5526Z" fill="white"/>
                <defs>
                <filter id="filter0_d_430_11651" x="0.717064" y="0.370385" width="47.1458" height="47.1458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="2.51846" dy="2.51846"/>
                <feGaussianBlur stdDeviation="1.57404"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0901961 0 0 0 0 0.211765 0 0 0 0.0392157 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_430_11651"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_430_11651" result="shape"/>
                </filter>
                </defs>
            </svg>
            <div class="wss-left-success-score-container">
                <h2 [style]="{'font-size':'4.2rem', 'font-weight':'600', 'line-height': '.85'}">
                    {{reportingDetailsValues.reportingDetails.responseRate}}
                </h2>
                <h2 [style]="{'font-size': '2rem','line-height': '1.2'}">%</h2>
            </div>
        </div>
        <div class="reporting-results-details-container">
            <div class="results-numbers-container">
                <div pTooltip="{{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_participants_check_tooltip' | translate) : ('custom_poll_participants_res_tooltip' | translate:{feedbackTemplate: ''})}}" tooltipStyleClass="response-distribution-tooltip" tooltipPosition="bottom" class="details-font-container details-font">
                    <span style="margin-right: .5rem;">{{'custom_poll_main_reporting_details_participants' | translate}}</span>
                    <span class="doted-space"></span>
                    <h2 style="margin-bottom: 0;">{{reportingDetailsValues.reportingDetails.assignments}}</h2>
                </div>
                <div pTooltip="{{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_complete_check_tooltip' | translate) : ('custom_poll_complete_res_tooltip' | translate)}}" tooltipStyleClass="response-distribution-tooltip" tooltipPosition="bottom" class="details-font-container details-font">
                    <span style="margin-right: .5rem;">
                        {{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_main_reporting_details_complete_check' | translate) : ('custom_poll_main_reporting_details_complete_res' | translate)}}</span>
                    <span class="doted-space"></span>
                    <h2 style="margin-bottom: 0;">{{reportingDetailsValues.reportingDetails.completedAssignments}}</h2>
                </div>
                <div pTooltip="{{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_partially_complete_check_tooltip' | translate) : ('custom_poll_partially_complete_res_tooltip' | translate)}}" tooltipStyleClass="response-distribution-tooltip" tooltipPosition="bottom" class="details-font-container details-font">
                    <span style="margin-right: .5rem;">
                        {{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_main_reporting_details_partially_complete_check' | translate):('custom_poll_main_reporting_details_partially_complete_res' |  translate)}}</span>
                    <span class="doted-space"></span>
                    <h2 style="margin-bottom: 0;">{{reportingDetailsValues.reportingDetails.incompleteAssignments}}</h2>
                </div>
                <div pTooltip="{{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_missing_check_tooltip' | translate) : ('custom_poll_missing_res_tooltip' | translate)}}" tooltipStyleClass="response-distribution-tooltip" tooltipPosition="bottom" class="details-font-container details-font">
                    <span style="margin-right: .5rem;">
                        {{reportingDetailsValues.type === 'onboarding' ? ('custom_poll_main_reporting_details_missing_check' | translate):('custom_poll_main_reporting_details_missing_res' | translate)}}</span>
                    <span class="doted-space"></span>
                    <h2 style="margin-bottom: 0;">{{reportingDetailsValues.reportingDetails.emptyAssignments}}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="loop-container loop-details">
        <div class="loop-details-column logo-column">
            <h2 style="font-size: 1.6rem;">{{'custom_poll_main_reporting_details_workspace' | translate}}</h2>
            <h1 class="organisatio-name">{{reportingDetailsValues.workspaceName}}</h1>
        </div>
        <div class="loop-details-column periode-column">
            <h2 style="font-size: 1.6rem;">{{'custom_poll_main_reporting_details_period' | translate}}</h2>
            <div class="d-flex align-items-center ml-auto">
                <img src="../../../../../assets/svg/calendar-gray.svg">
                <h2 class="periode-date">{{(reportingDetailsValues.startDate | date:'d MMM yyyy')+'  -  '+(reportingDetailsValues.endDate | date:'d MMM yyyy')}}</h2>
            </div>
        </div>
    </div>
</div>