import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { KnobModule } from 'primeng/knob';
import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';

import { ReportingContainerComponent } from './containers/reporting-container/reporting-container.component';
import { ReportingLoopDetailsComponent } from './components/reporting-loop-details/reporting-loop-details.component';
import { CategoriesAndStatementsComponent } from './components/categories-and-statements/categories-and-statements.component';
import { ScaledQuestionDistributionComponent } from './components/scaled-question-distribution/scaled-question-distribution.component';
import { ActionPlanComponent } from './components/action-plan/action-plan.component';
import { OnboardingCategoriesComponent } from './components/onboarding-categories/onboarding-categories.component';

@NgModule({
  declarations: [
    ReportingContainerComponent,
    ReportingLoopDetailsComponent,
    CategoriesAndStatementsComponent,
    ScaledQuestionDistributionComponent,
    ActionPlanComponent,
    OnboardingCategoriesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    AccordionModule,
    DropdownModule,
    KnobModule,
    PaginatorModule,
    TabViewModule,
    CalendarModule,
    ChartModule,
    AppRoutingModule,
  ]
})
export class CustomLoopReportingModule { }
