import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterComponent} from './pages/register/register.component';
import {OnboardingComponent} from './pages/onboarding/onboarding.component';
import {EmailConfirmationComponent} from './components/email-confirmation/email-confirmation.component';
import {FeedbackLoopCongratsComponent} from './components/feedback-loop-congrats/feedback-loop-congrats.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {LoginComponent} from './pages/login/login.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AuthenticationService} from "./services/authentication.service";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ReplacePipe} from "./helpers/replace.pipe";
import {GetDisplayNamePipe} from "./helpers/get-display-name.pipe";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {PaidFeatureComponent} from './components/paid-feature/paid-feature.component';
import {HomeLayoutComponent} from "./layouts/home-layout.component";
import {LoginLayoutComponent} from "./layouts/login-layout.component";
import {ActivateAccountComponent} from './pages/activateaccount/activate-account.component';
import {ErrorInterceptor} from "./helpers/error.interceptor";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../environments/environment";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatCardModule} from "@angular/material/card";
import {NgxDropzoneModule} from "ngx-dropzone";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CarouselModule} from "primeng/carousel";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {RadioButtonModule} from "primeng/radiobutton";
import {FeedbackQuestionsComponent} from './components/feedback-questions/feedback-questions.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MatInputModule} from "@angular/material/input";
import {CalendarModule} from "primeng/calendar";
import {AutoCompleteModule} from "angular-ngx-autocomplete";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {
  PasswordStrenghtMetterComponent
} from './components/password-strenght-metter/password-strenght-metter.component';
import { NgxFlagPickerModule } from "ngx-flag-picker";
import {CardModule} from 'primeng/card';
import {TeamsOverviewComponent} from './pages/teams-overview/teams-overview.component';
import {RippleModule} from "primeng/ripple";
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from "primeng/badge";
import {KnobModule} from 'primeng/knob';
import {DropdownModule} from 'primeng/dropdown';
import {HeaderComponent} from "./components/header/header.component";
import {TeamsComponent} from "./pages/teams/teams.component";
import {NgxEchartsModule} from 'ngx-echarts';
import {MyActionsComponent} from './components/my-actions/my-actions.component';
import {MatMenuModule} from '@angular/material/menu';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {InputTextModule} from 'primeng/inputtext';
import {MenuComponent} from './components/menu/menu.component';
import {AccountComponent} from "./pages/settings/account/account.component";
import {WorkspaceComponent} from "./pages/settings/workspace/workspace.component";
import {StatementsComponent} from "./pages/settings/statements/statements.component";
import {IndicatorsComponent} from "./pages/settings/indicators/indicators.component";
import {MethodsComponent} from "./pages/settings/methods/methods.component";
import {ActionsComponent} from "./pages/settings/actions/actions.component";
import {AnalyseComponent} from './components/analyse/analyse.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {AccordionModule} from 'primeng/accordion';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {BillingComponent} from './pages/settings/billing/billing.component';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {ModalsComponent} from './components/modals/modals.component';
import {AddMembersComponent} from './components/add-members/add-members.component';
import {AddTeamsComponent} from './components/add-teams/add-teams.component';
import {OrganizeTeamsComponent} from './components/organize-teams/organize-teams.component';
import {CollectorComponent} from './components/collector/collector.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TooltipModule} from 'primeng/tooltip'
import {ConfirmationService, MessageService} from "primeng/api";
import {AddToWorkspaceComponent} from "./pages/add-to-workspace/add-to-workspace.component";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {MemberInfoComponent} from './components/member-info/member-info.component';
import {SkeletonModule} from "primeng/skeleton";
import {WorkspaceMembersComponent} from './pages/workspace-members/workspace-members.component';
import {SplitButtonModule} from "primeng/splitbutton";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {TeamActionsComponent} from './pages/team-actions/team-actions.component';
import {TeamAnalyseComponent} from './pages/team-analyse/team-analyse.component';
import {PixelModule} from "ngx-pixel";
import {WorkspaceMembersLeadersComponent} from "./pages/workspace-members-leader/workspace-members-leader.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import { FeedbackQuestionsDemoComponent } from './components/feedback-questions-demo/feedback-questions-demo.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { ErrorComponent } from './pages/error/error.component';
import { WorkspaceOverviewComponent } from './pages/workspace-overview/workspace-overview.component';
import { ResponseDistributionComponent } from './components/response-distribution/response-distribution.component';
import { CustomLoopsModule } from './pages/custom-loops/custom-loops.module';
import { CustomLoopReportingModule } from './pages/custom-loop-reporting/custom-loop-reporting.module';
import { ChartModule } from 'primeng/chart';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    OnboardingComponent,
    EmailConfirmationComponent,
    FeedbackLoopCongratsComponent,
    PaidFeatureComponent,
    LoginComponent,
    ResetPasswordComponent,
    ReplacePipe,
    GetDisplayNamePipe,
    HomeLayoutComponent,
    LoginLayoutComponent,
    ActivateAccountComponent,
    FeedbackQuestionsComponent,
    PasswordStrenghtMetterComponent,
    TeamsOverviewComponent,
    HeaderComponent,
    TeamsOverviewComponent,
    TeamsComponent,
    MyActionsComponent,
    MenuComponent,
    AccountComponent,
    WorkspaceComponent,
    ActionsComponent,
    StatementsComponent,
    MethodsComponent,
    IndicatorsComponent,
    AnalyseComponent,
    DashboardComponent,
    BillingComponent,
    NotificationsComponent,
    ModalsComponent,
    AddMembersComponent,
    AddTeamsComponent,
    OrganizeTeamsComponent,
    CollectorComponent,
    AddToWorkspaceComponent,
    MemberInfoComponent,
    WorkspaceMembersComponent,
    TeamActionsComponent,
    TeamAnalyseComponent,
    WorkspaceMembersLeadersComponent,
    FeedbackQuestionsDemoComponent,
    ErrorComponent,
    WorkspaceOverviewComponent,
    ResponseDistributionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    AutocompleteLibModule,
    NgxStripeModule.forRoot(environment.stripe),
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    NgxDropzoneModule,
    NgSelectModule,
    MatTooltipModule,
    CarouselModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    NgxSliderModule,
    MatInputModule,
    CalendarModule,
    AutoCompleteModule,
    NgxIntlTelInputModule,
    NgxFlagPickerModule,
    CardModule,
    RippleModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    KnobModule,
    DropdownModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    MatMenuModule,
    SweetAlert2Module.forRoot(),
    InputTextModule,
    ProgressBarModule,
    AccordionModule,
    TabViewModule,
    TableModule,
    InputSwitchModule,
    TooltipModule,
    ConfirmDialogModule,
    SkeletonModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    BsDropdownModule,
    PixelModule.forRoot({enabled: true, pixelId: environment.pixel}),
    OverlayPanelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CustomLoopsModule,
    CustomLoopReportingModule,
    ChartModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    AuthenticationService,
    ModalsComponent,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
